*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: white;
  box-sizing: border-box;
  font-family: sans-serif;
  font-weight: 400;
  overflow-x: 'hidden' !important;
  overflow-y: auto !important;
  width: 100vw;
}
